const exampleMetadata = [
  {
    title: 'Love Takes Over',
    artist: 'Lucky Strike',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '01B',
  },
  {
    title: 'Opus Dei (Extended Mix)',
    artist: 'ReOrder & Ram',
    album: '',
    genre: 'Trance',
    comment: 'FSOE267',
    label: 'FSOE',
    year: '2017',
    key: '07A',
  },
  {
    title: 'Flying High',
    artist: 'Block-E',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '09A',
  },
  {
    title: 'Twice Around The Moon (Chaos Remix)',
    artist: 'Rampant & Obie vs Darwin',
    album: 'Hardcore Underground EP Vol. 4',
    genre: 'Hardcore',
    comment: 'HULTDCD007',
    label: 'Hardcore Underground',
    year: '2013',
    key: '08B',
  },
  {
    title: 'Snowy world',
    artist: 'you',
    album: 'Touitsu Compilation Vol.1',
    genre: 'Hardcore',
    comment: 'TIR002',
    label: 'Touitsu Recordings',
    year: '2013',
    key: '08B',
  },
  {
    title: 'Headbangerz (Chasers Remix)',
    artist: 'DJ Shimamura',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2014',
    key: '01B',
  },
  {
    title: 'The Dragon (Extended Mix)',
    artist: 'Darren Styles',
    album: '',
    genre: 'Hardcore',
    comment: 'ELFX004',
    label: 'Electric Fox',
    year: '2018',
    key: '06A',
  },
  {
    title: 'Hurricane (S3RL Remix)',
    artist: 'HS-Project',
    album: 'Hurricane',
    genre: 'Hardcore',
    comment: 'EHC007',
    label: 'Envious Hardcore',
    year: '2011',
    key: '03A',
  },
  {
    title: 'Hands That Built Us Hardcore (X-Cyte Remix)',
    artist: 'Buzz & Stevie-D Ft. Adam',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '11A',
  },
  {
    title: 'Walk On Water (SynthWulf & Rude Effect Remix)',
    artist: 'Basshunter',
    album: 'RuffBeatz: Audio Crysis',
    genre: 'Hardcore',
    comment: 'RB005',
    label: 'RuffBeatz',
    year: '2011',
    key: '12A',
  },
  {
    title: 'Cry (S3RL Remix)',
    artist: 'Starstruck',
    album: 'Cry - The Final EP',
    genre: 'Hardcore',
    comment: 'AWF050',
    label: 'Australia With Force',
    year: '2010',
    key: '08B',
  },
  {
    title: 'Escape',
    artist: 'Shox vs Big-D & Bolo',
    album: 'Escape',
    genre: 'Hardcore',
    comment: 'SW43',
    label: 'Sinthetic Records',
    year: '2014',
    key: '11B',
  },
  {
    title: 'Teeth Grinder',
    artist: 'Joey Riot',
    album: "Feel Complete / We're Gonna Rock You / Dark Heart / Teeh Grinder",
    genre: 'Hardcore',
    comment: 'LTR0030',
    label: 'Lethal Theory',
    year: '2011',
    key: '10A',
  },
  {
    title: 'Succeed (Original Mix)',
    artist: 'Fracus & Darwin Ft. Mark Slammer',
    album: 'Filth And Dumb Hatred',
    genre: 'Hardcore',
    comment: 'HUDSCD008',
    label: 'Hardcore Underground',
    year: '2014',
    key: '11B',
  },
  {
    title: 'I ♡ OTOKONOK♂',
    artist: 'Jh-Anu',
    album: 'Above The World II',
    genre: 'Hardcore',
    comment: 'PWK002',
    label: 'PAO-WHO Records',
    year: '2016',
    key: '04A',
  },
  {
    title: 'Follow Where You Are',
    artist: 'Pinnacle & Mr Sparkle',
    album: 'Follow Where You Are',
    genre: 'Hardcore',
    comment: 'EVL002',
    label: 'Lucky Breaks Digital',
    year: '2013',
    key: '04A',
  },
  {
    title: 'Laddergoat',
    artist: 'Archefluxx',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '01B',
  },
  {
    title: 'It All Clears Over (Emergency Vocal Mix)',
    artist: 'Darren Hotchkiss',
    album: 'My Music',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2012',
    key: '06A',
  },
  {
    title: 'Cyclapse',
    artist: 'Evolutionize',
    album: 'Cyclapse / Worthless',
    genre: 'Hardcore',
    comment: 'RLNTDIGI102',
    label: 'Relentless Digital',
    year: '2013',
    key: '11A',
  },
  {
    title: 'A Place Where Love Is Born (Escea Remix)',
    artist: 'Tycoos & Sandro Mireno',
    album: 'A Place Where Love Is Born: Remixes',
    genre: 'Trance',
    comment: 'ABSK072',
    label: 'Abora Recordings',
    year: '2020',
    key: '05B',
  },
  {
    title: 'Inertia (Extended Mix)',
    artist: 'Darren Porter',
    album: '',
    genre: 'Trance',
    comment: 'FSOE324',
    label: 'FSOE',
    year: '2018',
    key: '09A',
  },
  {
    title: 'Here With Me (2008 Mix)',
    artist: 'Lumin8',
    album: 'Here With Me Remixes',
    genre: 'Hardcore',
    comment: 'AWF020',
    label: 'Australia With Force',
    year: '2009',
    key: '05B',
  },
  {
    title: "Gettin' Rough",
    artist: 'Cat Like Thief',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2014',
    key: '06A',
  },
  {
    title: "Takin' Me Higher (Doofus da Dingo's Donk Remix)",
    artist: 'Darren Styles',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '03B',
  },
  {
    title: 'The Way (Chaos Mix)',
    artist: 'Divine Inspiration',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2012',
    key: '08B',
  },
  {
    title: 'City Lights',
    artist: 'HyperJuice Ft. EVO+ & Jinmenusagi',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2016',
    key: '08A',
  },
  {
    title: 'Cross The Line',
    artist: 'Reese',
    album: 'K12 Studios: Just the Tip',
    genre: 'Hardcore',
    comment: 'K12EP001',
    label: 'K12 Studios',
    year: '2008',
    key: '09A',
  },
  {
    title: "Venera (Vee's Theme) (Extended Mix)",
    artist: 'Ferry Corsten & Gouryella',
    album: '',
    genre: 'Trance',
    comment: 'FLASH144',
    label: 'Flashover Recordings',
    year: '2017',
    key: '02B',
  },
  {
    title: 'Suited & Booted (Stormtrooper Remix)',
    artist: 'DJ Delite, Rampant & Darwin',
    album: 'Hardcore Underground EP Vol. 4',
    genre: 'Hardcore',
    comment: 'HULTDCD007',
    label: 'Hardcore Underground',
    year: '2013',
    key: '06B',
  },
  {
    title: 'Forever Shining',
    artist: 'Outforce',
    album: '',
    genre: 'Hardcore',
    comment: 'GTYM072',
    label: 'Go Tell Yo Momma',
    year: '2013',
    key: '11B',
  },
  {
    title: 'Guitar Hero vs Through The Darkness (Technikore Remix)',
    artist: 'Dougal & Gammer vs Mickey Skedale Ft. Jenna',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '08A',
  },
  {
    title: 'Someday (Fallon Remix)',
    artist: 'Impact & Resist',
    album: '',
    genre: 'Hardcore',
    comment: 'TFOH0078',
    label: 'The Future Of Hardcore',
    year: '2014',
    key: '09B',
  },
  {
    title: 'Fly Away (Now) (Eufeion Hardcore Bootleg)',
    artist: 'Panty & Stocking',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2014',
    key: '12A',
  },
  {
    title: 'Happy (Hardcore) Birthday to You!',
    artist: 'Ranzor',
    album: 're:BOOTed',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2014',
    key: '09B',
  },
  {
    title: 'Within Those Eyes',
    artist: 'MesoPhunk',
    album: 'Lucky Lotus Volume 1',
    genre: 'Hardcore',
    comment: 'LLRC001',
    label: 'Lucky Lotus Records',
    year: '2014',
    key: '04B',
  },
  {
    title: 'Feeble Effort',
    artist: 'Cryptix',
    album: 'Hard Trance Anthems: Vol. 5',
    genre: 'Hardcore',
    comment: 'MCTX5',
    label: 'Metamorph Platinum',
    year: '2014',
    key: '07A',
  },
  {
    title: "The Darkness (Hazy's Slipping Into Acid Remix)",
    artist: 'Haze',
    album: 'Hardcore Energy 2',
    genre: 'Hardcore',
    comment: 'HCNRG002',
    label: 'Nu Energy Records',
    year: '2011',
    key: '05A',
  },
  {
    title: 'Your Love Is My Drug',
    artist: 'Rising Star Ft. Marie Louise',
    album: 'Your Love Is My Drug / What A Feeling',
    genre: 'Hardcore',
    comment: 'REC011',
    label: 'Recycled Records',
    year: '2011',
    key: '02B',
  },
  {
    title: 'The Journey (Exclusive)',
    artist: 'Mansy',
    album: 'Hardcore Producer Vol.1: Remixed',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2010',
    key: '06B',
  },
  {
    title: 'Turntable DeeJays',
    artist: 'Eufeion',
    album: 'Never Stop Raving: Hardcore Explosion',
    genre: 'Hardcore',
    comment: 'NSRCD01',
    label: 'NSR Records',
    year: '2009',
    key: '01B',
  },
  {
    title: 'Dance With Me',
    artist: 'Audio Warfare',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'Audio Weaponry',
    year: '2012',
    key: '12A',
  },
  {
    title: 'Metal Gear',
    artist: 'Spirit Wolf',
    album: '',
    genre: 'Freeform',
    comment: '',
    label: '',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Activate your Energy (Naggy Remix)',
    artist: 'Whitey & Cotts',
    album: '',
    genre: 'Hardcore',
    comment: 'OVR010',
    label: 'Only Vibes Recordings',
    year: '2008',
    key: '10A',
  },
  {
    title: "Who's Afraid of Classic Trance",
    artist: 'InnerSync',
    album: 'Elven Kingdom',
    genre: 'Trance',
    comment: 'VRS030',
    label: 'VERSE (Equinox)',
    year: '2016',
    key: '12A',
  },
  {
    title: 'Lethal Industry',
    artist: 'Inverse & Orbit1',
    album: 'Lethal Industry',
    genre: 'Hardcore',
    comment: 'AWF049',
    label: 'Australia With Force',
    year: '2010',
    key: '11B',
  },
  {
    title: 'Forgotten Legend (Kiyoi & Eky Extended Remix)',
    artist: 'tranzLift',
    album: 'Forgotten Legend (Kiyoi & Eky Remix)',
    genre: 'Trance',
    comment: 'BTSR310',
    label: 'Beyond The Stars Recordings',
    year: '2020',
    key: '10A',
  },
  {
    title: 'Bring Me Down (Hyperforce Remix)',
    artist: "Flyin' & Sparky Ft. Charm",
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'Beatfeet',
    year: '2010',
    key: '10B',
  },
  {
    title: 'The War',
    artist: 'DJ Luna-C',
    album: 'Crush On Hardcore: Sequel Of The V',
    genre: 'Hardcore',
    comment: 'COFREECD001',
    label: 'Crush On',
    year: '2010',
    key: '07A',
  },
  {
    title: 'Follow Me',
    artist: 'Kman',
    album: 'USHC Vol.1',
    genre: 'Hardcore',
    comment: 'XHCD017',
    label: 'X-TREME HARD',
    year: '2013',
    key: '03B',
  },
  {
    title: 'Higher, Forever (Darwin Remix)',
    artist: 'Contraption',
    album: '',
    genre: 'Hardcore',
    comment: 'RFUFREE07',
    label: 'RFU Recordingz',
    year: '',
    key: '11A',
  },
  {
    title: 'Exodus (Haze & T.C. Remix)',
    artist: 'Ten Tun',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG33',
    label: 'Whojamaflip',
    year: '2013',
    key: '03A',
  },
  {
    title: 'Nothing Can Be Further From The Truth (Extended Mix)',
    artist: 'Skylex & Emma Horan',
    album: '',
    genre: 'Trance',
    comment: 'AMSTR242',
    label: 'Amsterdam Trance Records (RazNitzanMusic)',
    year: '2018',
    key: '10A',
  },
  {
    title: 'FM-200 (S3RL Remix)',
    artist: 'Douglas',
    album: 'FM-200 / FM-200 (S3RL Remix) / Send Me (Stabilize Remix)',
    genre: 'Freeform',
    comment: 'NUNRG091',
    label: 'Nu Energy Records',
    year: '2011',
    key: '12A',
  },
  {
    title: 'Orenda (Cyril Ryaz Remix)',
    artist: 'The WLT',
    album: 'Orenda',
    genre: 'Trance',
    comment: 'MND309',
    label: 'Mondo Records',
    year: '2019',
    key: '04A',
  },
  {
    title: 'Show Me',
    artist: 'Technikore Ft. Nathalie',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '10B',
  },
  {
    title: 'Before I Wake (Extended Mix)',
    artist: 'Amine Maxwell',
    album: '',
    genre: 'Trance',
    comment: 'AVAW024',
    label: 'AVA White',
    year: '2017',
    key: '07A',
  },
  {
    title: 'Breakaway',
    artist: 'Fracus & Darwin',
    album: 'Balancing Act',
    genre: 'Drum & Bass',
    comment: 'HUAACD001',
    label: 'Hardcore Underground',
    year: '2012',
    key: '03A',
  },
  {
    title: 'Have To easii Survive (Olly P Mashup)',
    artist: 'empyrean tears & JAKAZiD',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2017',
    key: '11A',
  },
  {
    title: 'Sweet Surrender',
    artist: 'Petrol Heads Ft. Kate & Dee',
    album: 'Dusk Till Dawn - The Complete Works',
    genre: 'Hardcore',
    comment: 'EECD017',
    label: 'Electronica Exposed',
    year: '2008',
    key: '11A',
  },
  {
    title: 'Together (SynthWulf Remix)',
    artist: 'Dancelordz',
    album: '',
    genre: 'Freeform',
    comment: 'RENRG004',
    label: 'Relentless Energy',
    year: '2010',
    key: '08A',
  },
  {
    title: 'Fallen',
    artist: 'Shox vs Xandro',
    album: '',
    genre: 'Freeform',
    comment: 'BFB006',
    label: 'B-Form',
    year: '2012',
    key: '08A',
  },
  {
    title: '4AM (Extended Mix)',
    artist: 'Technikore',
    album: '',
    genre: 'Hardcore',
    comment: 'ELFX010',
    label: 'Electric Fox',
    year: '2018',
    key: '05A',
  },
  {
    title: 'Sora Blue',
    artist: 'Hixxy',
    album: '',
    genre: 'Hardcore',
    comment: 'HTID001',
    label: 'HTID',
    year: '2011',
    key: '04B',
  },
  {
    title: 'Let U Go (X-FIR3 & Unleashed Remix)',
    artist: 'S3RL Ft. Wendy',
    album: '',
    genre: 'Hardcore',
    comment: 'RLNTDIGI077',
    label: 'Relentless Digital',
    year: '2011',
    key: '09A',
  },
  {
    title: 'Make It',
    artist: 'The Acolyte',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'FutureNoize',
    year: '2006',
    key: '08A',
  },
  {
    title: 'Sunrize (Rize Remix)',
    artist: 'Angel City',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2014',
    key: '08B',
  },
  {
    title: 'Magic',
    artist: 'Nakura',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '06B',
  },
  {
    title: 'It Pumps You',
    artist: 'Technikore',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '07B',
  },
  {
    title: 'Ravebow Road',
    artist: 'Ranzor',
    album: 're:BOOTed',
    genre: 'Freeform',
    comment: '',
    label: '',
    year: '2014',
    key: '12B',
  },
  {
    title: "Sky's the Limit",
    artist: 'Ranzor & TeHLoY',
    album: 'Touitsu Compilation Vol.1',
    genre: 'Hardcore',
    comment: 'TIR002',
    label: 'Touitsu Recordings',
    year: '2013',
    key: '06B',
  },
  {
    title: 'Kamehameha',
    artist: 'S3RL Ft. j0hnny',
    album: '',
    genre: 'Hardcore',
    comment: 'EMF008',
    label: 'EMFA Music',
    year: '2011',
    key: '01A',
  },
  {
    title: 'EON BREAK (Ranzor Hardcore Edit)',
    artist: 'Virtual Self',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2018',
    key: '08A',
  },
  {
    title: "I'm By Your Side (Kiyoi & Eky Remix)",
    artist: 'Tuomas.L, ELV, Rebecca Louise Burch',
    album: "I'm By Your Side",
    genre: 'Trance',
    comment: 'TAR138019',
    label: 'TAR#138',
    year: '2018',
    key: '05A',
  },
  {
    title: 'The Power Within',
    artist: 'Gammer',
    album: '',
    genre: 'Hardcore',
    comment: 'GARCH09',
    label: 'Gammer: The Archives',
    year: '2012',
    key: '11A',
  },
  {
    title: 'Do You Believe',
    artist: 'Nakura',
    album: 'Hardcore Producer Vol.1: Remixed',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2010',
    key: '01B',
  },
  {
    title: 'Psymbiosis Blistering (Breaks Mix)',
    artist: 'Ephexis & Seventy-7',
    album: 'Nu Energy 100 - The Final Chapter',
    genre: 'Hardcore',
    comment: 'NUNRG100',
    label: 'Nu Energy Records',
    year: '2011',
    key: '12B',
  },
  {
    title: 'UnderGround Sound (Sparkz Remix)',
    artist: 'Dandy Crouch',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '08B',
  },
  {
    title: '1 Ting',
    artist: 'TC & Bit On The Side',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG21',
    label: 'Whojamaflip',
    year: '2007',
    key: '03A',
  },
  {
    title: '#TehCreep',
    artist: 'Olly P & Cotts',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2016',
    key: '01A',
  },
  {
    title: 'Cruising (Darren Styles Remix)',
    artist: 'N&K vs Denis The Menace Ft. Alex Prince',
    album: '',
    genre: 'Hardcore',
    comment: 'JB05B',
    label: 'Junkbox',
    year: '2010',
    key: '06A',
  },
  {
    title: 'Redux (Unfinished)',
    artist: 'Substanced & Aryx',
    album: '',
    genre: 'Freeform',
    comment: '',
    label: '',
    year: '2014',
    key: '06B',
  },
  {
    title: 'In Your Head (Rave Mix)',
    artist: 'Nu Foundation',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2010',
    key: '06B',
  },
  {
    title: 'Love Is Love',
    artist: 'Starstruck Ft. S3RL',
    album: 'Starstruck Ft. S3RL EP',
    genre: 'Hardcore',
    comment: 'AWF044',
    label: 'Australia With Force',
    year: '2010',
    key: '06B',
  },
  {
    title: 'Back at Them',
    artist: 'Hotchkiss',
    album: 'Open Your Eyes',
    genre: 'Hardcore',
    comment: 'EV155',
    label: 'Evolution Records',
    year: '2016',
    key: '10B',
  },
  {
    title: 'Lover (Inverse & Orbit1 Remix)',
    artist: 'Weaver & A.M.S',
    album: 'Lover',
    genre: 'Hardcore',
    comment: 'HARDCOREMASIF7',
    label: 'Hardcore Masif',
    year: '2011',
    key: '01A',
  },
  {
    title: 'Underground (Mansy [More Underground] Remix)',
    artist: 'Dandy Crouch',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Stand aloof from here',
    artist: "DJ Shimamura Ft. Numb'n'dub",
    album: 'VORTEX',
    genre: 'Hardcore',
    comment: 'DNCD-013',
    label: 'Dynasty Records',
    year: '2018',
    key: '08A',
  },
  {
    title: 'Worlds Apart (Auscore Remix)',
    artist: 'P.H.A.T.T. Ft. Kate Smith',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2008',
    key: '01B',
  },
  {
    title: 'Power Force (Dandy Crouch Remix)',
    artist: 'Sparkz',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '07B',
  },
  {
    title: 'Tranny Stream',
    artist: 'Ryu☆',
    album: 'HARDCORE UNITED TOKYO',
    genre: 'Hardcore',
    comment: 'S2TB0007,08',
    label: 'S2TB Recording',
    year: '2013',
    key: '01A',
  },
  {
    title: 'Goodbye',
    artist: 'Buzz & Stevie-D Ft. Jessica Palmer',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '07A',
  },
  {
    title: 'Summer Rain',
    artist: 'Starstruck Ft. Gemma',
    album: 'Summer Rain EP',
    genre: 'Hardcore',
    comment: 'AWF035',
    label: 'Australia With Force',
    year: '2010',
    key: '06A',
  },
  {
    title: 'Shooting Star (Al Storm Remix)',
    artist: 'Bang!',
    album: 'Shooting Star / Love, Life & Happiness (Remixes)',
    genre: 'Hardcore',
    comment: '247HC002',
    label: '24/7 Hardcore',
    year: '2009',
    key: '03A',
  },
  {
    title: 'The Core Elite',
    artist: 'Bulby G Ft. Fingaz MC',
    album: 'Never Stop Raving: Hardcore Explosion',
    genre: 'Hardcore',
    comment: 'NSRCD01',
    label: 'NSR Records',
    year: '2009',
    key: '01B',
  },
  {
    title: 'Dropzone (Auscore Remix)',
    artist: 'Darren Styles',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2009',
    key: '10A',
  },
  {
    title: 'Picture Show (Audio Warfare RMX)',
    artist: 'Easy Big Fella',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'Audio Weaponry',
    year: '2012',
    key: '11A',
  },
  {
    title: 'The Power Within 2011',
    artist: 'Joey Riot Ft. Kelly C',
    album:
      "I'll Be Your Lover / Kevin And Perry Go Ravin / The Power Within 2010 / Speaker Check",
    genre: 'Hardcore',
    comment: 'LTR033',
    label: 'Lethal Theory',
    year: '2011',
    key: '04A',
  },
  {
    title: 'Darkside 2011',
    artist: 'Nakura',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '07A',
  },
  {
    title: 'Beatific (D-tor Remix)',
    artist: 'Cruze',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '11B',
  },
  {
    title: 'Young Forever (VIP Mix)',
    artist: 'Lucky Strike',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '12B',
  },
  {
    title: 'Jump & Bounce',
    artist: 'TC & Scott Devotion',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG15',
    label: 'Whojamaflip',
    year: '2007',
    key: '09B',
  },
  {
    title: 'The Highest Point',
    artist: 'Eternal Waves vs J-Squared',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '07B',
  },
  {
    title: 'Tronic',
    artist: 'X-FIR3',
    album: '',
    genre: 'Hardcore',
    comment: 'RLNTDIGI094',
    label: 'Relentless Digital',
    year: '2012',
    key: '07B',
  },
  {
    title: "Lookin' Like That",
    artist: 'Señor Hotdog',
    album: 'Influx vs Señor Hotdog EP',
    genre: 'Hardcore',
    comment: 'AWF031',
    label: 'Australia With Force',
    year: '2010',
    key: '12B',
  },
  {
    title: 'All I Want (Extended Mix)',
    artist: 'Ciaran McAuley & Clare Stagg',
    album: '',
    genre: 'Trance',
    comment: 'NA001',
    label: 'Nocturnal Animals Music',
    year: '2019',
    key: '08B',
  },
  {
    title: 'Realms & Rough-Cuts',
    artist: 'Fracus & Darwin',
    album: 'Balancing Act',
    genre: 'Hardcore',
    comment: 'HUAACD001',
    label: 'Hardcore Underground',
    year: '2012',
    key: '03A',
  },
  {
    title: 'Hit Ya Hard',
    artist: 'Sharkey, Eryk Orpheus & Kevin Energy',
    album: 'Hardcore Energy 2',
    genre: 'Hardcore',
    comment: 'HCNRG002',
    label: 'Nu Energy Records',
    year: '2011',
    key: '04A',
  },
  {
    title: 'Flying Higher (I ♥ Ayu Bootleg)',
    artist: 'Helblinde',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '11B',
  },
  {
    title: 'Fake Mars',
    artist: 'Milo',
    album: '',
    genre: 'Hardcore',
    comment: 'P0009',
    label: 'Project 00',
    year: '2011',
    key: '10B',
  },
  {
    title: 'Seven Colours of Sea (Extended Mix)',
    artist: 'Derek Ryan & Melissa R. Kaplan',
    album: '',
    genre: 'Trance',
    comment: 'RNMR175',
    label: 'RNM (RazNitzanMusic)',
    year: '2019',
    key: '03A',
  },
  {
    title: "It Ain't hard to Tell",
    artist: 'The Acolyte',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'FutureNoize',
    year: '2006',
    key: '08A',
  },
  {
    title: 'Glasseyes',
    artist: 'Qygen',
    album: 'RuffBeatz & Freeformaniacs - Freeformatted',
    genre: 'Freeform',
    comment: 'RB009',
    label: 'RuffBeatz',
    year: '2012',
    key: '11A',
  },
  {
    title: 'Lights Will Guide You Home',
    artist: 'Driftmoon',
    album: '',
    genre: 'Trance',
    comment: 'WAO138036',
    label: "Who's Afraid Of 138?!",
    year: '2015',
    key: '07A',
  },
  {
    title: 'Come Running (CXH Intro Mix)',
    artist: 'Darren Styles Ft. Francis Hill',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2007',
    key: '03A',
  },
  {
    title: 'Kevin & Perry Go Raving',
    artist: 'Kurt vs Nizzle',
    album:
      "I'll Be Your Lover / Kevin And Perry Go Ravin / The Power Within 2010 / Speaker Check",
    genre: 'Hardcore',
    comment: 'LTR033',
    label: 'Lethal Theory',
    year: '2011',
    key: '08A',
  },
  {
    title: "Don't Wanna Be",
    artist: 'Mansy',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '05B',
  },
  {
    title: 'Rock The Disco',
    artist: 'Duncan Oatham',
    album: 'Nu Energy 100 - The Final Chapter',
    genre: 'Hardcore',
    comment: 'NUNRG100',
    label: 'Nu Energy Records',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Epidemic',
    artist: 'Chris Ross',
    album: 'Never Stop Raving: Hardcore Explosion',
    genre: 'Hardcore',
    comment: 'NSRCD01',
    label: 'NSR Records',
    year: '2009',
    key: '09A',
  },
  {
    title: 'Sandstorm (Talla 2XLC Remix)',
    artist: 'Darude',
    album: '',
    genre: 'Trance',
    comment: '',
    label: '',
    year: '2016',
    key: '09A',
  },
  {
    title: 'Night Is Calling',
    artist: 'Type 41, XiJaro & Pitch',
    album: '',
    genre: 'Trance',
    comment: 'BTSR140',
    label: 'Beyond The Stars Recordings',
    year: '2016',
    key: '02B',
  },
  {
    title: 'Army Of Duty',
    artist: 'K-rlos & Peryk',
    album: '',
    genre: 'Freeform',
    comment: 'SRD005',
    label: 'Sinthetic Records',
    year: '2012',
    key: '06A',
  },
  {
    title: 'Wave Of Love',
    artist: 'Sash Dee Ft. Dionne',
    album: '',
    genre: 'Hardcore',
    comment: 'BFHC038',
    label: 'Bassface Recordings',
    year: '2010',
    key: '08B',
  },
  {
    title: 'In Your Arms (Al Storm Remix)',
    artist: 'Fracus & Orbit1 Ft. Lisa Abbott',
    album: 'Dusk Till Dawn - The Complete Works',
    genre: 'Hardcore',
    comment: 'EECD017',
    label: 'Electronica Exposed',
    year: '2008',
    key: '01A',
  },
  {
    title: 'My Immortal ',
    artist: 'Wizbit',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG18',
    label: 'Whojamaflip',
    year: '2007',
    key: '06B',
  },
  {
    title: 'Time 2 Move',
    artist: 'Al Storm & Kutski',
    album: 'Hardcore Underground EP Vol. 3',
    genre: 'Hardcore',
    comment: 'HULTDCD006',
    label: 'Hardcore Underground',
    year: '2013',
    key: '11B',
  },
  {
    title: 'This Is (Your) Anthem',
    artist: 'Mitomoro',
    album: 'Above The World II',
    genre: 'Hardcore',
    comment: 'PWK002',
    label: 'PAO-WHO Records',
    year: '2016',
    key: '11B',
  },
  {
    title: 'Welcome 2 Tomorrow',
    artist: 'Inverse & Orbit1',
    album: "Don't Give Up / Welcome 2 Tomorrow",
    genre: 'Hardcore',
    comment: 'AWF041',
    label: 'Australia With Force',
    year: '2010',
    key: '05A',
  },
  {
    title: 'Faith is Blind',
    artist: 'DBL',
    album: 'Hardcore Underground EP Vol. 3',
    genre: 'Hardcore',
    comment: 'HULTDCD006',
    label: 'Hardcore Underground',
    year: '2013',
    key: '02A',
  },
  {
    title: 'Body Bag',
    artist: 'DJ Kurt vs Nizzle & MPH',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '11B',
  },
  {
    title: 'Spin Me Up',
    artist: 'Hutchy',
    album: 'Hardcore Producer Vol.3',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '08A',
  },
  {
    title: "Rockin'",
    artist: 'Al Storm',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG05',
    label: 'Whojamaflip',
    year: '2007',
    key: '07B',
  },
  {
    title: 'There Are No Happy Endings',
    artist: 'Substanced',
    album: 'When The World Collapses',
    genre: 'Freeform',
    comment: '',
    label: 'FINRG Recordings',
    year: '2012',
    key: '07A',
  },
  {
    title: 'Geneva Part II (Alchemiist Remix)',
    artist: 'Substanced',
    album: 'When The World Collapses',
    genre: 'Freeform',
    comment: '',
    label: 'FINRG Recordings',
    year: '2012',
    key: '07A',
  },
  {
    title: 'N74',
    artist: 'Scott Bounce',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '02A',
  },
  {
    title: 'Show Me The Way',
    artist: 'Spirit Wolf',
    album: '',
    genre: 'Trance',
    comment: '',
    label: '',
    year: '2012',
    key: '08B',
  },
  {
    title: 'The Island 1 (Dandy Crouch Remix)',
    artist: 'Pendulum',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Drug Abuse',
    artist: 'Audio X Ft. Serenity',
    album: 'Never Stop Raving: Hardcore Explosion',
    genre: 'Hardcore',
    comment: 'NSRCD01',
    label: 'NSR Records',
    year: '2009',
    key: '08A',
  },
  {
    title: 'Si-On',
    artist: 'Viper',
    album: '',
    genre: 'Hardcore',
    comment: 'VACFREE003',
    label: 'Vacuum Music',
    year: '2011',
    key: '05A',
  },
  {
    title: 'Falling Over The Moon (Audio Warfare & Kal-El Remix)',
    artist: 'DJ Daydream',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'Audio Weaponry',
    year: '2012',
    key: '02A',
  },
  {
    title: 'Over The Moon (S3RL Remix)',
    artist: 'Oceania',
    album: 'Over The Moon EP',
    genre: 'Hardcore',
    comment: 'AWF029',
    label: 'Australia With Force',
    year: '2010',
    key: '10A',
  },
  {
    title: 'Hardcore Frontiers 2011',
    artist: 'Helblinde',
    album: 'RuffBeatz: Audio Crysis',
    genre: 'Hardcore',
    comment: 'RB005',
    label: 'RuffBeatz',
    year: '2011',
    key: '01B',
  },
  {
    title: 'Exploration Of Space',
    artist: 'Cosmic Gate vs Skeets',
    album: 'Hardcore Producer Vol.2: The Future of Hardcore',
    genre: 'Hardcore',
    comment: '',
    label: 'Hardcore Producer',
    year: '2011',
    key: '08A',
  },
  {
    title: "Nobody Said It Was Easy (Gammer's Blatant Rip Off)",
    artist: 'Gammer',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '07B',
  },
  {
    title: 'Empty Echoing (Extended Mix)',
    artist: 'RedSound, SoundLift, Cathy Burton',
    album: '',
    genre: 'Trance',
    comment: 'AMSTR273',
    label: 'Amsterdam Trance Records (RazNitzanMusic)',
    year: '2019',
    key: '11A',
  },
  {
    title: 'Unfaithful',
    artist: 'Rhianna vs Technikore',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '05A',
  },
  {
    title: 'Stay Tonight (Desert Hardcore Edit)',
    artist: 'Gammer Ft. Dylan Matthew',
    album: '',
    genre: 'Hardcore',
    comment: 'MCS610',
    label: 'Monstercat',
    year: '2017',
    key: '11A',
  },
  {
    title: 'Against The World (Ganar Remix)',
    artist: 'Morgan Page & Michael S.',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'G Records',
    year: '2014',
    key: '08B',
  },
  {
    title: 'Bastard Monkey Hands',
    artist: 'Ponder',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG17',
    label: 'Whojamaflip',
    year: '2007',
    key: '07A',
  },
  {
    title: 'Met U On The Dancefloor (Instrumental Mix)',
    artist: 'Zero-2 & Starstruck',
    album: 'The Brisbane Hardcore EP',
    genre: 'Hardcore',
    comment: 'AWF042',
    label: 'Australia With Force',
    year: '2010',
    key: '02B',
  },
  {
    title: 'Satellite (Update)',
    artist: 'Darren Styles',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'Futureworld',
    year: '2013',
    key: '01B',
  },
  {
    title: 'Pococore',
    artist: 'Ranzor',
    album: 're:BOOTed',
    genre: 'TBD',
    comment: '',
    label: '',
    year: '2014',
    key: '09B',
  },
  {
    title: 'Guitar Hero (Technikore) x Anybody Else But You (Bad Corey Mash Up)',
    artist: 'Technikore x Dougal & Gammer',
    album: 'Guitar Hero Mash Up EP',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '08B',
  },
  {
    title: 'Like I Feel (Squad-E Remix)',
    artist: 'D:CODE',
    album: 'Like I Feel',
    genre: 'Hardcore',
    comment: 'GLOBE697',
    label: 'All Around The World',
    year: '2007',
    key: '03B',
  },
  {
    title: 'Seizure (X-FIR3 Epic Riff Remix)',
    artist: 'Clowny',
    album: 'We R Relentless Presents The Best Of X-FIR3',
    genre: 'Hardcore',
    comment: 'RLNTDIGI082',
    label: 'Relentless Digital',
    year: '2012',
    key: '11B',
  },
  {
    title: 'Intro',
    artist: 'Subi',
    album: 'When The World Collapses',
    genre: 'Freeform',
    comment: '',
    label: 'FINRG Recordings',
    year: '2012',
    key: '05A',
  },
  {
    title: 'Guitar Hero (Kors K) x Anybody Else But You (Bad Corey Mash Up)',
    artist: 'Kors K x Dougal & Gammer',
    album: 'Guitar Hero Mash Up EP',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '08B',
  },
  {
    title: 'What Is A DJ? (DJ Edit)',
    artist: 'S3RL & Jimini Cricket',
    album: '',
    genre: 'Hardcore',
    comment: 'EMF100',
    label: 'EMFA Music',
    year: '2018',
    key: '01A',
  },
  {
    title: 'See 4 Eva (Cube::Hard Remix)',
    artist: 'Petrol Heads Ft. Kate, Dee, MC Chit Chat',
    album: 'Dusk Till Dawn - The Complete Works',
    genre: 'Hardcore',
    comment: 'EECD017',
    label: 'Electronica Exposed',
    year: '2008',
    key: '09A',
  },
  {
    title: 'Til I Die (Bridson Remix)',
    artist: 'Defekt Ft. Leomie',
    album: 'Til I Die (Remixes)',
    genre: 'Hardcore',
    comment: 'OHC026',
    label: 'Original Hardcore',
    year: '2012',
    key: '12B',
  },
  {
    title: 'Falling',
    artist: 'Kracy & Compressor',
    album: 'Musik Box Anthem 2013',
    genre: 'Hardcore',
    comment: 'MBR003',
    label: 'Musik Box',
    year: '2013',
    key: '08A',
  },
  {
    title: 'All My Dreams',
    artist: 'Dougal & Gammer Ft. Niki Mak',
    album: 'Boom Ba De / All My Dreams',
    genre: 'Hardcore',
    comment: 'EPP059',
    label: 'Essential Platinum',
    year: '2010',
    key: '12B',
  },
  {
    title: "Don't Give up",
    artist: 'Inverse & Orbit1',
    album: "Don't Give Up / Welcome 2 Tomorrow",
    genre: 'Hardcore',
    comment: 'AWF041',
    label: 'Australia With Force',
    year: '2010',
    key: '07A',
  },
  {
    title: 'Adagio',
    artist: 'Darwin',
    album: '',
    genre: 'Hardcore',
    comment: 'JAL66',
    label: 'Just Another Label',
    year: '2006',
    key: '08A',
  },
  {
    title: 'Labyrinth (Extended Mix)',
    artist: 'Steve Dekay',
    album: '',
    genre: 'Trance',
    comment: 'WAO138272',
    label: "Who's Afraid Of 138?!",
    year: '2020',
    key: '06A',
  },
  {
    title: 'Back Home (Fady & Mina vs Omar Sherif Remix)',
    artist: 'Hazem Beltagui & Adara',
    album: 'FSOE - Best Of 2018',
    genre: 'Trance',
    comment: 'FSOE344',
    label: 'FSOE',
    year: '2018',
    key: '01B',
  },
  {
    title: 'Dance (Damaged Remix)',
    artist: 'Apollo',
    album: '',
    genre: 'Hardcore',
    comment: 'EHF005',
    label: 'Envious Hardcore',
    year: '2010',
    key: '11B',
  },
  {
    title: 'Pandora (Gisbo Remix)',
    artist: 'HS Project',
    album: 'Gisbo Promotional Album',
    genre: 'Hardcore',
    comment: '',
    label: 'In Your Head Records',
    year: '2011',
    key: '07A',
  },
  {
    title: 'Dark Heart',
    artist: 'Kurt vs Noizy Boiz',
    album: "Feel Complete / We're Gonna Rock You / Dark Heart / Teeh Grinder",
    genre: 'Hardcore',
    comment: 'LTR0030',
    label: 'Lethal Theory',
    year: '2011',
    key: '10A',
  },
  {
    title: 'Shine On Me',
    artist: 'Brisk & Fracus Ft. Malaya',
    album: "Shine On Me / Nothing In Our Way / Radio Rockin' 2010",
    genre: 'Hardcore',
    comment: 'BB098',
    label: 'Blatant Beats',
    year: '2011',
    key: '02A',
  },
  {
    title: 'Chemical Warfare',
    artist: "Cynista & Safe 'N' Sound",
    album: 'Nu Energy 100 - The Final Chapter',
    genre: 'Hardcore',
    comment: 'NUNRG100',
    label: 'Nu Energy Records',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Left 4 Dead',
    artist: 'Naggy',
    album: '',
    genre: 'Hardcore',
    comment: 'OVR022',
    label: 'Only Vibes Recordings',
    year: '2009',
    key: '08A',
  },
  {
    title: 'Liquid Future',
    artist: 'Phenex',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2009',
    key: '08A',
  },
  {
    title: 'Exodus',
    artist: 'NrgMind',
    album: '',
    genre: 'Trance',
    comment: 'SR013',
    label: 'Subatomic Recordings',
    year: '2018',
    key: '11A',
  },
  {
    title: 'Sunlight (Chaos Remix)',
    artist: 'Darren Styles & Seduction Ft. Alison Wade',
    album: 'Sunlight',
    genre: 'Hardcore',
    comment: 'MAXIMUMIMPACT36',
    label: 'Maximum Impact',
    year: '2008',
    key: '10B',
  },
  {
    title: 'Coming Up (Kevin Energy Hard Remix)',
    artist: 'A.B',
    album: 'Hardcore Energy',
    genre: 'Freeform',
    comment: 'CYFIDC15',
    label: 'Can You Feel It Media',
    year: '2010',
    key: '08A',
  },
  {
    title: 'Afterlife (Archefluxx Remix)',
    artist: 'Archefluxx',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '08A',
  },
  {
    title: 'Language (Stu Infinity Hardcore Mix)',
    artist: 'Porter Robinson',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2012',
    key: '11B',
  },
  {
    title: "We're Flying High",
    artist: 'Kurt ',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2011',
    key: '07B',
  },
  {
    title: 'Exploder',
    artist: 'Substanced',
    album: 'When The World Collapses',
    genre: 'Hard Trance',
    comment: '',
    label: 'FINRG Recordings',
    year: '2012',
    key: '09A',
  },
  {
    title: 'Tsing Tao',
    artist: 'The Acolyte',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: 'FutureNoize',
    year: '2006',
    key: '08A',
  },
  {
    title: 'Heal My Soul (Kevin Energy Remix)',
    artist: 'The Acolyte Ft. Amy',
    album: 'Dusk Till Dawn - The Complete Works',
    genre: 'Hardcore',
    comment: 'EECD017',
    label: 'Electronica Exposed',
    year: '2008',
    key: '09B',
  },
  {
    title: 'How Will I Know (Extended Club Mix)',
    artist: 'The Thrillseekers & Stine Grove',
    album: '',
    genre: 'Trance',
    comment: 'FSOE281',
    label: 'FSOE',
    year: '2018',
    key: '07B',
  },
  {
    title: 'Jumping!! (DJ Noriken Hardcokeshi Bootleg)',
    artist: 'Rhodanthe',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2013',
    key: '05B',
  },
  {
    title: 'Yggdrasil',
    artist: 'Wyrm & Surtr',
    album: 'RuffBeatz & Freeformaniacs - Freeformatted',
    genre: 'Freeform',
    comment: 'RB009',
    label: 'RuffBeatz',
    year: '2012',
    key: '06B',
  },
  {
    title: 'The World To Come (Andy Blueman Mix)',
    artist: 'Reconceal & Andy Blueman',
    album: 'The World To Come',
    genre: 'Trance',
    comment: 'ABSK038',
    label: 'Abora Recordings',
    year: '2017',
    key: '08A',
  },
  {
    title: "Stars Collide (Essex Boyz vs Al Storm 'Hardcore' Mix)",
    artist: 'Al Storm Ft. Taya',
    album: 'Stars Collide Remix EP',
    genre: 'Hardcore',
    comment: '247HC051',
    label: '24/7 Hardcore',
    year: '2011',
    key: '10B',
  },
  {
    title: 'Chasing Skies',
    artist: 'TC',
    album: '',
    genre: 'Hardcore',
    comment: 'WHODIG01',
    label: 'Whojamaflip',
    year: '2007',
    key: '05A',
  },
  {
    title: 'Flaming June (Darwin Remix)',
    artist: 'BT',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2009',
    key: '11A',
  },
  {
    title: 'Heartbeatz (MOB & Supreme Remix)',
    artist: 'Mark Breeze & Styles Ft. Karen Danzig',
    album: '',
    genre: 'Hardcore',
    comment: 'FWORLD028',
    label: 'Futureworld',
    year: '2011',
    key: '06B',
  },
  {
    title: 'Right Back (Solis & Sean Truby Extended Remix)',
    artist: 'Yuri Kane',
    album: '',
    genre: 'Trance',
    comment: 'FLASHTR100',
    label: 'Flashover Trance',
    year: '2018',
    key: '09A',
  },
  {
    title: 'Heaven Trace (Alternate High Remix)',
    artist: 'Se.Ra.Phic',
    album: 'Heaven Trace (2019 Mixes)',
    genre: 'Trance',
    comment: 'BTSR292',
    label: 'Beyond The Stars Recordings',
    year: '2019',
    key: '02A',
  },
  {
    title: 'Sweetheart',
    artist: 'Maria Healy',
    album: '',
    genre: 'Trance',
    comment: 'SUBC183',
    label: 'Subculture',
    year: '2019',
    key: '04A',
  },
  {
    title: 'This Mind Of Mine',
    artist: 'Technikore & JTS',
    album: '',
    genre: 'Hardcore',
    comment: 'ONESEVENTY29',
    label: 'OneSeventy',
    year: '2018',
    key: '07A',
  },
  {
    title: 'Moscow River (Extended Mix)',
    artist: 'Giuseppe Ottaviani',
    album: '',
    genre: 'Trance',
    comment: 'BH9750',
    label: 'Black Hole Recordings',
    year: '2019',
    key: '08B',
  },
  {
    title: 'Post Script',
    artist: 'Moogl3 & REViSE',
    album: 'RuffBeatz: Hardcore Resistance',
    genre: 'Hardcore',
    comment: 'RB010',
    label: 'RuffBeatz',
    year: '2012',
    key: '03B',
  },
  {
    title: 'White Hood',
    artist: 'Kiyoi & Eky',
    album: 'White Hood',
    genre: 'Trance',
    comment: 'TF025',
    label: 'Tranceform',
    year: '2017',
    key: '07A',
  },
  {
    title: 'Take a ride (Gammer Remix)',
    artist: 'Darwin Ft. Justin',
    album: '',
    genre: 'Hardcore',
    comment: 'JAL44',
    label: 'Just Another Label',
    year: '2005',
    key: '09B',
  },
  {
    title: 'My Direction (Hyperforce & Overdrive Bootleg)',
    artist: 'D:CODE',
    album: '',
    genre: 'Hardcore',
    comment: '',
    label: '',
    year: '2012',
    key: '09A',
  },
  {
    title: 'Follow The Light',
    artist: 'Flawless & Bananaman Ft. Dahni-Bria',
    album: '',
    genre: 'Hardcore',
    comment: 'JJ002',
    label: 'Jump 4 Joy',
    year: '2014',
    key: '06A',
  },
  {
    title: 'Everytime We Say Goodbye (Technikore Remix)',
    artist: 'Al Storm Ft. Malaya',
    album: '',
    genre: 'Hardcore',
    comment: '247HC055',
    label: '24/7 Hardcore',
    year: '2011',
    key: '12B',
  },
];

export default exampleMetadata;
