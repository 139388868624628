type Props = {
  size?: number;
};

export const AppleLogo = ({size = 22}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.08 17.15C19.75 17.91 19.36 18.62 18.9 19.27C18.4629 19.9376 17.9531 20.5547 17.38 21.11C16.78 21.67 16.13 21.96 15.43 21.98C14.93 21.98 14.33 21.83 13.63 21.54C13.0173 21.2723 12.3584 21.1263 11.69 21.11C11.07 21.11 10.4 21.26 9.69 21.54C8.99 21.84 8.41 21.98 7.97 22C7.3 22.03 6.64 21.73 5.97 21.11C4.4796 19.6437 3.35883 17.8443 2.7 15.86C2.24775 14.5778 2.01121 13.2295 2 11.87C2 10.4 2.32 9.12 2.95 8.06C3.42856 7.2303 4.11194 6.53722 4.93481 6.047C5.75767 5.55678 6.69253 5.28581 7.65 5.26C8.18 5.26 8.88 5.43 9.74 5.76C10.2671 5.99016 10.8217 6.15149 11.39 6.24C11.58 6.24 12.19 6.05 13.23 5.67C14.22 5.31 15.05 5.17 15.73 5.22C16.5443 5.24311 17.3423 5.45362 18.062 5.83516C18.7818 6.21671 19.4039 6.75903 19.88 7.42C19.1231 7.82493 18.4933 8.43189 18.0608 9.17338C17.6283 9.91487 17.4099 10.7618 17.43 11.62C17.4493 12.5832 17.7465 13.5204 18.2858 14.3187C18.8251 15.117 19.5836 15.7425 20.47 16.12C20.35 16.48 20.22 16.82 20.08 17.15V17.15ZM15.85 0.45C15.85 1.55 15.45 2.57 14.65 3.52C13.69 4.65 12.52 5.3 11.25 5.2C11.2129 4.55802 11.3043 3.91509 11.519 3.30893C11.7336 2.70276 12.0672 2.14556 12.5 1.67C12.91 1.2 13.43 0.81 14.06 0.5C14.6103 0.215877 15.2124 0.0458115 15.83 0L15.85 0.44V0.45Z"
      fill="currentColor"
    />
  </svg>
);

export const LinuxLogo = ({size = 22}: Props) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
  >
    <path
      d="M7.2 10.8c0-1.7.8-3.3 2-4.2l-1.8-3A8.9 8.9 0 004 8.1a3.2 3.2 0 01.1 5.4A8.9 8.9 0 007.5 18L9.2 15c-1.2-1-2-2.5-2-4.1z"
      fill="currentColor"
    />
    <path
      d="M4.6 11A2.3 2.3 0 110 11a2.3 2.3 0 014.6 0zM17.5 16.5c.6 0 1 .1 1.5.4a8.9 8.9 0 002.4-5.5l-3.6-.1a5.3 5.3 0 01-7.4 4.3l-1.8 3.1a8.9 8.9 0 005.7.8c.2-1.7 1.5-3 3.2-3z"
      fill="currentColor"
    />
    <path
      d="M19.8 19.7a2.3 2.3 0 11-4.6 0 2.3 2.3 0 014.6 0zM19.2 5c-.5.3-1.1.5-1.8.5A3.2 3.2 0 0114.2 2a8.8 8.8 0 00-5.6.8l1.8 3.1a5.3 5.3 0 017.4 4.2h3.6c-.1-2-1-3.8-2.2-5.2z"
      fill="currentColor"
    />
    <path d="M19.7 2.3a2.3 2.3 0 11-4.6 0 2.3 2.3 0 014.6 0z" fill="currentColor" />
  </svg>
);

export const WindowsLogo = ({size = 22}: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2772 10.8436L8.15304 18.0447C8.15304 18.0447 5.78205 16.5227 4.09856 16.6619C2.42388 16.801 0.969551 17.0532 0 17.5489L2.09776 10.3566C2.09776 10.3566 4.45112 9.27821 6.41667 9.41736C8.3734 9.54781 9.94231 10.5914 10.2772 10.835V10.8436ZM10.6298 9.53912L12.7276 2.40768C8.97276 -0.444895 4.57452 1.92065 4.57452 1.92065L2.45913 9.11297C2.45913 9.11297 4.76843 8.28677 6.51362 8.11283C8.26763 7.93889 10.621 9.53912 10.621 9.53912H10.6298ZM15.0897 12.9483C13.6178 12.9744 11.379 11.5394 11.379 11.5394L9.28125 18.6882C9.66907 18.8361 10.9295 20.0188 13.0272 20.1232C15.125 20.2276 17.4431 19.1666 17.4431 19.1666C17.4431 19.1666 19.4792 12.1221 19.5409 12.0177C18.1388 12.6296 16.6229 12.9466 15.0897 12.9483V12.9483ZM19.5409 12.009C19.5497 12.009 19.5497 12.009 19.5409 12.009V12.009ZM17.399 4.5645C16.1095 4.41933 14.8829 3.93583 13.847 3.16431L11.7316 10.261C11.7316 10.261 13.4503 11.9134 15.8566 11.7829C18.2804 11.6438 19.5849 10.8871 19.9111 10.7828L22 3.62524C22 3.62524 19.2236 4.77323 17.399 4.5645V4.5645Z"
      fill="currentColor"
    />
  </svg>
);
